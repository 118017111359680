<template>
  <div class="w-100">
    <b-card
      :header-class="`${plan.background_card}-bg header-card-membership`"
      class="main-card-package-membership"
    >
      <template #header>
        <h3 class="text-white">{{ plan.name }}</h3>
      </template>
      <div class="container-data">
          <div class="my-2 pb-1 border-bottom text-center">
            <span :class="`text-price`">${{ separatebycomma(plan.price) }}</span>
            <span class="text-month">USD</span>

          </div>
          <div class="d-flex flex-column text-left">
            
          <strong class="w-100 text-left mb-1">{{ $t('creator.include') }} </strong>
          <div :class="{'border-bottom mb-1 pb-1': index !== plan.options.length - 1}" v-for="(text, index) in plan.options" :key="index">
            <p class="text-muted-custom-plans p-0">
              <feather-icon icon="CheckIcon" class="mr-02"></feather-icon>
              <span>{{ text }}</span>
            </p>
          </div>
        </div>
      </div>

      <template #footer >
        <b-button
          :class="`${plan.background_card}-bg text-white button-plan`"
          :variant="`${plan.background_card}-bg`"
          @click="buyPackage(plan.credit)"
        >
         {{ $t('creator.buyNow') }}
        </b-button>
      </template>
    </b-card>
  </div>
</template>
  
<script>
import { 
  BCard, 
  BButton,
} from "bootstrap-vue";
import { separatebycomma } from "@/libs/utils/formats";
import others_service from "@/services/others";

export default {
  name: 'cardPackage',
  components: {
    BCard,
    BButton,
  },
  props: {
    plan: {
      type: Object,
    },
  },

  data() {
    return {
      separatebycomma,
    }
  },
  methods: {
    buyPackage(credit) {
      others_service.buyCreditsPackage({quantity: credit}).then(response => {
        if (response.url) {
          window.open(response.url)
        }
      })
    }
  }
};
</script>
<style>
.main-card-package-membership .b-card {
  width: 328px;
  border-radius: 0.7rem;

}
</style>
<style scoped lang="scss">
.button-plan {
  width: -webkit-fill-available;
}
.card-business-bg {
  background: #57daac;
}
.card-pro-bg {
  background: #55a6c4;
}
.card-basic-bg {
  background: #fb1475;
}
.main-card-package-membership {
  width: 328px;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 0.7rem;
}
.header-card-membership {
  border-radius: 0.7rem 0.7rem 0 0;
}
.mr-02 {
  margin-right: 0.2em;
}
.text-muted-custom-plans {
  color: #6c757d;
  font-family: 'avenir-medium';
  display: flex;
  align-items: center;
  .small-text {
    font-size: 11px;
  }
}
.text-price {
  font-size: 36px;
  text-align: center;
  margin-right: 5px;
  font-family: 'avenir-medium';
}
.text-month {
  font-size: 0.85rem !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
</style>